<template>
<div>
  <yard />
</div>
</template>

<script>

import yard from '@/components/yard.vue';

export default {
  name: 'YardView',
  components: { yard },
};
</script>
