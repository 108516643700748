<template>
  <v-card>
    <v-card-title>
      Yard &nbsp;&nbsp;&nbsp;
      <v-sheet elevation="2" height="20" width="20" color="yellow"></v-sheet>
      &nbsp;&nbsp;&nbsp;
      <span>Pending Move</span>
      <v-spacer>
      </v-spacer>
      <v-text-field
        v-model="searchTerm"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <div v-if="showScheduleMoveComponent">
      <v-dialog
        v-bind:persistent="true"
        v-model="showScheduleMoveComponent"
        max-width="800">
        <schedule-move selectionType="full" />
      </v-dialog>
    </div>
    <edit-location-equipment-dialog></edit-location-equipment-dialog>
    <v-data-table
      :headers="headers"
      :search="searchTerm"
      :items="equipmentWithLocation"
      v-bind:disable-pagination="true"
      v-bind:hide-default-footer="true"
      v-bind:fixed-header="true"
      height="78vh"
      :expanded="equipmentWithLocation"
      :item-class="itemRowBackground"
      :loading="isLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.number="{ item }">
        <b>{{ item.number }} </b>
      </template>
      <template
        v-slot:expanded-item="{ headers, item }"
        color="primary"
      >
        <td
          v-show="!item.hasPendingMove"
          id="expanded-area"
          :colspan="headers.length"
          bgcolor="white"
        >
          <v-btn
            id="moveButton"
            class="mr-4 mb-0 mt-n4"
            color="primary"
            min-width="8vw"
            @click="scheduleMoveClicked(item)"
          >
            MOVE
          </v-btn>
          <v-btn
            v-if="userHasAdminRole"
            id="editButton"
            class="mr-4 mb-0 mt-n4"
            color="neutral"
            min-width="8vw"
            @click="editClicked(item)"
          >
            EDIT
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ScheduleMove from './scheduleMove.vue';
import dateMixins from '../mixins/dateMixins';
import carrierMixin from '../mixins/carrierMixins';
import EditLocationEquipmentDialog from './editLocationEquipmentDialog.vue';

export default {
  data: () => ({
    searchTerm: '',
    headers: [
      {
        text: 'Time',
        value: 'formattedDate',
        sort(a, b) {
          const date1 = new Date(a);
          const date2 = new Date(b);
          return date1 - date2;
        },
      },
      { text: 'Type', value: 'type' },
      { text: 'Equipment Number', value: 'number' },
      { text: 'Size', value: 'size' },
      { text: 'State', value: 'state' },
      { text: 'Zone', value: 'location.groups' },
      { text: 'Location', value: 'location.displayName' },
      { text: 'SCAC', value: 'carrier.scac' },
      { text: 'Carrier', value: 'carrier.name' },
      { text: 'Booking #', value: 'bookingNumber' },
      { text: 'Customer', value: 'customer.name' },
    ],
  }),
  components: { ScheduleMove, EditLocationEquipmentDialog },
  mixins: [dateMixins, carrierMixin],
  computed: {
    ...mapGetters('location', ['isLoading']),
    ...mapGetters('move', ['showScheduleMoveComponent']),
    ...mapGetters('equipment', ['equipmentWithLocation']),
    ...mapGetters('account', ['user']),
    userHasAdminRole() {
      if (this.user) {
        return this.user.roles.includes('Admin');
      }
      return false;
    },
  },
  methods: {
    ...mapActions('location', ['setFilter', 'setShowEditEquipmentDialog']),
    ...mapActions('equipment', ['setEquipmentToMove']),
    ...mapActions('move', ['setShowScheduleMoveComponent']),
    ...mapActions('gate', ['setGateEntry']),
    ...mapActions('check', ['setSelectedCheck']),
    itemRowBackground(item) {
      if (item.hasPendingMove) {
        return 'yellow';
      }

      return '';
    },
    scheduleMoveClicked(equipment) {
      this.setEquipmentToMove(equipment);
      this.setShowScheduleMoveComponent(true);
    },
    editClicked(equipment) {
      const gateEntry = {};
      gateEntry.location = _.cloneDeep(equipment.location);
      gateEntry.equipment = _.cloneDeep(equipment);
      this.setGateEntry(gateEntry);
      this.setSelectedCheck({});
      this.setShowEditEquipmentDialog(true);
    },
  },
  created() {
    if (this.equipmentWithLocation.length === 0) {
      this.$store.dispatch('location/getLocations');
    }
  },
  beforeDestroy() {
    this.setFilter('');
  },
};
</script>

<style>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    margin-top: 8px;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }
  #expanded-area {
    border-bottom: thin solid rgba(0, 0, 0, 0.15);
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) >
  td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0);
}
</style>
